import React from 'react'
import {connect} from "react-redux"

import $ from 'jquery'
import WOW from 'wow.js'
import {withTranslation} from "react-i18next"


import Header from '../components/header/header'
import Intro from '../components/intro/intro'
import Steps from '../components/steps/steps'
import Section from '../components/section/section'
import Reviews from '../components/reviews/reviews'
import Form from './form'
import Banner from './banner'
import Faq from '../components/faq/faq'
import Footer from '../components/footer/footer'

import introImage from '../assets/img/background-intro.jpg'
import principeImage1 from '../assets/icons/principe1.png'
import principeImage2 from '../assets/icons/principe2.png'
import principeImage3 from '../assets/icons/principe3.png'
import checkImage from '../assets/icons/check.png'
import stepImage1 from '../assets/img/step1.png'
import stepImage2 from '../assets/img/step2.png'
import stepImage3 from '../assets/img/step3.png'
import sectionImage1 from '../assets/img/photo1.png'
import sectionImage2 from '../assets/img/photo2.png'
import sectionImage3 from '../assets/img/photo3.png'
import constants from '../config/constants'

import './home.css'

class Home extends React.Component {
  componentDidMount() {
    // WOW animations initialization
    new WOW().init()

    // Handle back to top button click
    $('.btn-scroll-top').on('click', function () {
      $('body,html').animate({scrollTop: 0}, 800)
    })

    // Scroll header and toggle fixed position
    $(window).scroll(function () {
      let scrolledHeight = $(this).scrollTop()

      // Display back to top button
      if (scrolledHeight >= 100) {
          $('.btn-scroll-top').fadeIn(900).removeClass('d-none')
      } else if (!$('.btn-scroll-top').hasClass('d-none')) {
          $('.btn-scroll-top').fadeOut(900).addClass('d-none')
      }

      // Toggle fixed position header section
      if (scrolledHeight >= 50) {
          $('nav').removeClass('nav-background-clear')
          $('nav').fadeIn().addClass('nav-background-light')
          $('#nav-logo-clear').removeClass('nav-logo-visible')
          $('#nav-logo-clear').addClass('nav-logo-hidden')
          $('#nav-logo-light').removeClass('nav-logo-hidden')
          $('#nav-logo-light').addClass('nav-logo-visible')
      } else {
          $('nav').removeClass('nav-background-light')
          $('nav').fadeIn().addClass('nav-background-clear')
          $('#nav-logo-clear').removeClass('nav-logo-hidden')
          $('#nav-logo-clear').addClass('nav-logo-visible')
          $('#nav-logo-light').removeClass('nav-logo-visible')
          $('#nav-logo-light').addClass('nav-logo-hidden')
      }
    })

    // Connexion à la dapp bar
    const btudapp = document.createElement('script')
    btudapp.src = 'https://btu-cdn.s3.eu-west-3.amazonaws.com/public/dappbar.min.js'
    btudapp.async = true
    document.body.appendChild(btudapp)
  }

  /**
   * Fonction appelée au clic sur le bouton de contact
   */
  onClickContact = () => {
    window.open('mailto:' + constants.url.mail,'_blank');
  }

  /**
   * Scrolle vers le formulaire
   */
  scrollToForm = () => {
    let target = $('#form')
    $('html, body').stop().animate({ scrollTop: $(target).offset().top - 50 }, 600)
  }

  render() {
    const {
      t,
    } = this.props

    return (
      <React.Fragment>
        <div id='btu-placeholder' style={{position: 'fixed', zIndex: 2}} />
        <Header useSuspense={false} />

        <Intro
          id='home'
          title={t('intro.title')}
          text={t('intro.text')}
          button={t('intro.button')}
          onClick={this.scrollToForm}
          image={introImage}
          centeredText
        />

        <Section
          image={sectionImage1}
          title={t('section_1.title')}
          content={(
            <ul className="section_list">
              <li>
                <h2>
                  <img src={principeImage1} alt="" />
                  {t('section_1.content.0.title')}
                </h2>
                <p>{t('section_1.content.0.text')}</p>
              </li>
              <li>
                <h2>
                  <img src={principeImage2} alt="" />
                  {t('section_1.content.1.title')}
                </h2>
                <p>{t('section_1.content.1.text')}</p>
              </li>
              <li>
                <h2>
                  <img src={principeImage3} alt="" />
                  {t('section_1.content.2.title')}
                </h2>
                <p>{t('section_1.content.2.text')}</p>
              </li>
            </ul>
          )}
        />

        <Section
          image={sectionImage2}
          title={t('section_2.title')}
          content={(
            <ul className="section_list">
              <li>
                <h2>
                  <img src={checkImage} alt="" />
                  {t('section_2.content.0.title')}
                </h2>
                <p>{t('section_2.content.0.text')}</p>
              </li>
              <li>
                <h2>
                  <img src={checkImage} alt="" />
                  {t('section_2.content.1.title')}
                </h2>
                <p>{t('section_2.content.1.text')}</p>
              </li>
              <li>
                <h2>
                  <img src={checkImage} alt="" />
                  {t('section_2.content.2.title')}
                </h2>
                <p>{t('section_2.content.2.text')}</p>
              </li>
            </ul>
          )}
          imageRight
        />

        <Steps
          title={t('how.title')}
          steps={[
            {
              image: stepImage1,
              title: t('how.content.0.title'),
              text: t('how.content.0.text'),
            },
            {
              image: stepImage2,
              title: t('how.content.1.title'),
              text: t('how.content.1.text'),
            },
            {
              image: stepImage3,
              title: t('how.content.2.title'),
              text: t('how.content.2.text'),
            },
          ]}
        />

        <Form
          t={t}
          useSuspense={false}
        />

        <Banner
          t={t}
          useSuspense={false}
        />

        {/*
        <Platforms
          t={t}
          useSuspense={false}
        />
        */}
        
        <Section
          image={sectionImage3}
          title={t('reviews.title')}
          content={(
            <Reviews
              content={[
                {
                  "text": t('reviews.content.0.text'),
                  "name": t('reviews.content.0.name')
                },
                {
                  "text": t('reviews.content.1.text'),
                  "name": t('reviews.content.1.name')
                },
                {
                  "text": t('reviews.content.2.text'),
                  "name": t('reviews.content.2.name')
                },
              ]}
            />
          )}
          imageRight
        />

        <Faq
          title={t('faq.title')}
          content={[
            {
              title: t('faq.content.0.title'),
              text: t('faq.content.0.text'),
            },
            {
              title: t('faq.content.1.title'),
              text: t('faq.content.1.text'),
            },
            {
              title: t('faq.content.2.title'),
              text: t('faq.content.2.text'),
            },
            {
              title: t('faq.content.3.title'),
              text: t('faq.content.3.text'),
            },
          ]}
        />

        <Footer
          t={t}
          useSuspense={false}
        />
        <div className="btn-scroll-top d-none text-decoration-none">
          <i className="la la-long-arrow-up la-2x"></i>
        </div>
      </React.Fragment>
    )
  }
}

Home.propTypes = {}

Home.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isOpenModal: state.btu.isOpenModal,
    modalAction: state.btu.modalAction,
    isLoading: state.btu.isLoading,
  }
}

Home = connect(mapStateToProps, null)(Home)
export default withTranslation()(Home)