import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import {Provider} from 'react-redux'
import {store} from './store'


import i18n from './i18n'
import {I18nextProvider} from "react-i18next"

const jquery = document.createElement('script')
jquery.src = 'https://code.jquery.com/jquery-3.4.1.min.js'
jquery.integrity = 'sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo='
jquery.crossOrigin = 'anonymous'
jquery.async = true
document.head.appendChild(jquery)
const web3js = document.createElement('script')
web3js.src = 'https://cdn.jsdelivr.net/gh/ethereum/web3.js@1.0.0-beta.34/dist/web3.min.js'
web3js.async = true
document.head.appendChild(web3js)


ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>
    , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
