import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from "reactstrap"

import './section.css'

class Section extends React.Component {
  render() {
    const {
      id,
      title,
      content,
      image,
      imageRight,
    } = this.props

    return (
      <section
        id={id}
      >
        <Container className="section-container">
          <Row>
            { !imageRight
              && (
                <Col lg={{size: 5}} className="section-image-container section-image-container-left">
                  <div className="home d-flex align-items-center align-items-lg-start">
                    <div className={'p-0'}>
                      <img src={image} alt="" />
                    </div>
                  </div>
                </Col>
              )
            }
            <Col lg={{size: 7}} className="section-main-container">
              <div className="home d-flex align-items-start align-items-lg-center">
                <div className={'p-lg-5 p-2'}>
                  <h1 className="section-main-title">{title}</h1>
                  <div className="section-main-content">{content}</div>
                </div>
              </div>
            </Col>
            { imageRight
              && (
                <Col lg={{size: 5}} className="section-image-container section-image-container-right">
                  <div className="home d-flex align-items-start align-items-xl-center">
                    <div className={'p-0'}>
                      <img src={image} alt="" />
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </Container>
      </section>
    )
  }
}

Section.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  content: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  image: PropTypes.string,
  onClick: PropTypes.func,
  imageRight: PropTypes.bool,
}

Section.defaultProps = {
  id: null,
  title: null,
  content: (<div></div>),
  image: null,
  imageRight: false,
}

export default Section