import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from "reactstrap"

import tokenImage from '../assets/img/token.png'

import './banner.css'

class Banner extends React.Component {
  render() {
    const {
      t,
    } = this.props

    return (
      <section>
        <Container className="banner-container">
          <Row>
            <Col sm={{size: 4}} className="">
              <div className="d-flex align-items-center justify-content-center">
                <div className={'p-0'}>
                  <h2>{t('banner.0.title')}</h2>
                  <p>{t('banner.0.text')}</p>
                </div>
              </div>
            </Col>
            <Col sm={{size: 4}} className="">
              <div className="d-flex align-items-center justify-content-center">
                <div className={'p-0'}>
                  <h2>{t('banner.1.title')}</h2>
                  <p>
                    <img src={tokenImage} alt="" />
                    {t('banner.1.text')}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={{size: 4}} className="">
              <div className="d-flex align-items-center justify-content-center">
                <div className={'p-0'}>
                  <h2>{t('banner.2.title')}</h2>
                  <p>{t('banner.2.text')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

Banner.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

Banner.defaultProps = { }

export default Banner