import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col, Button} from "reactstrap"

class Intro extends React.Component {
  render() {
    const {
      id,
      title,
      text,
      button,
      image,
      onClick,
      buttonDisabled,
      centeredText,
    } = this.props

    return (
      <section
        id={id}
        className={(centeredText ? 'home-centered-text' : '')}
        style={{ backgroundImage: 'url(' + image + ')'}}
      >
        <Container>
          <Row>
            <Col lg={{size: centeredText ? 12 : 7}}>
              <div className={'home d-flex align-items-xl-center ' + (centeredText ? 'align-items-center justify-content-center' : 'align-items-start')}>
                <div className={'p-xl-0 p-3'}>
                  <h1 className='home-title wow slideInUp wow slideInUp'
                    data-wow-duration="0.5s"
                    data-wow-delay="0.1s"
                    style={{fontSize: 36}}
                  >
                    {title}
                  </h1>
                  <p className={'home-description mb-4 wow slideInUp'}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.2s"
                  >
                    {text}
                  </p>
                  <Button
                    className={'btn btn-primary d-inline-flex align-items-center justify-content-between'}
                    onClick={onClick}
                    disabled={buttonDisabled}
                  >
                    {button}
                  </Button>
                </div>
              </div>
            </Col>
            { !centeredText
              && (
                <Col lg={{site: 5}}>
                  <div className="home d-flex align-items-start align-items-xl-center">
                    <div className={'p-xl-0 p-3'}>
                      <img src={image} alt="" />
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </Container>
      </section>
    )
  }
}

Intro.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  text: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  button: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  centeredText: PropTypes.bool,
}

Intro.defaultProps = {
  id: 'btu-intro',
  title: null,
  text: null,
  button: null,
  image: null,
  onClick: () => null,
  buttonDisabled: false,
  centeredText: false,
}

export default Intro