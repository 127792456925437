import React from 'react'
import PropTypes from 'prop-types'

import $ from 'jquery'

import './reviews.css'

class Reviews extends React.Component {
  state = {
    reviewIndex: 0,
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  tick = () => {
    const {
      content,
    } = this.props

    let {
      reviewIndex,
    } = this.state

    reviewIndex = (reviewIndex + 1) % content.length

    $('.review-text').fadeOut(function() {
      $(this).text('“' + content[reviewIndex].text + '”')
    }).fadeIn()

    $('.review-name').fadeOut(function() {
      $(this).text('- ' + content[reviewIndex].name)
    }).fadeIn()

    this.setState({ reviewIndex })
  }

  onClick = (index) => {
    const {
      content,
    } = this.props

    clearInterval(this.interval)

    $('.review-text').fadeOut(function() {
      $(this).text('“' + content[index].text + '”')
    }).fadeIn()

    $('.review-name').fadeOut(function() {
      $(this).text('- ' + content[index].name)
    }).fadeIn()

    this.setState({ reviewIndex: index })

    this.interval = setInterval(() => this.tick(), 3000)
  }

  render() {
    const {
      id,
      content,
    } = this.props

    const {
      reviewIndex
    } = this.state

    return (
      <div id={id} className="reviews-container">
        <p className={'review-text'}>
          {"“"}
          {content[0].text}
          {"”"}
        </p>
        <p className={'review-name'}>
          {'- '}
          {content[0].name}
        </p>
        <div className="reviews-bullets">
          {content.map((review, index) => (
            <span
              className="review-bullet-container"
              onClick={(event) => this.onClick(index)}
              key={review.name}
            >
              <span className={'review-bullet ' + (index === reviewIndex ? 'review-bullet-on' : 'review-bullet-off')} />
            </span>
          ))}
        </div>
      </div>
    )
  }
}

Reviews.propTypes = {
  id: PropTypes.string,
  content: PropTypes.instanceOf(Array),
}

Reviews.defaultProps = {
  id: null,
  content: [],
}

export default Reviews