import React from 'react'
import PropTypes from 'prop-types'

import './form.css'

class ListInput extends React.Component {
  state = {
    value: 0,
    error: false,
  }

  onChange = (value) => {
    const {
      onChange,
      fieldName,
    } = this.props

    this.setState({ value })

    const error = false

    onChange(
      fieldName,
      {
        value: parseInt(value),
        error,
      },
    )
  }

  render() {
    const {
      label,
      values,
      fieldName,
    } = this.props

    return (
      <div className="form_section">
        <label htmlFor={'form_' + fieldName}>
          {label}
        </label>
        <div className="form_select_wrapper">
          <select
            onChange={e => this.onChange(e.target.value)}
          >
            {values.map((value, index) => (
              <option
                key={value}
                value={index}
              >
                {value}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}

ListInput.propTypes = {
  label: PropTypes.string,
  values: PropTypes.instanceOf(Array),
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
}

ListInput.defaultProps = {
  label: null,
  value: [],
  fieldName: null,
  onChange: () => null,
}

export default ListInput