import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col, Card, CardBody} from 'reactstrap'

import './faq.css'

class Faq extends React.Component {
  render() {
    const {
      id,
      title,
      content,
    } = this.props

    return (
      <section id={id} className="faq-section">
        <Container>
          <Row>
            <Col>
              <h1 className={'text-center'}>
                {title}
              </h1>

              <div className="faq-questions">
                <Row>
                  { content.map(section => (
                    <Col
                      key={section.title}
                      md={6}
                    >
                      <Card
                        className={'mb-4 wow slideInUp faq-card'}
                        data-wow-duration="0.5s"
                        data-wow-delay="0.1s"
                      >
                        <CardBody>
                          <h3 className={'faq-question'}>
                            {section.title}
                          </h3>
                          <p className={'m-0 faq-description'}>
                            {section.text}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
      
        </Container>
      </section>
    )
  }
}

Faq.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.instanceOf(Array),
}

Faq.defaultProps = {
  id: null,
  title: null,
  content: [],
}

export default Faq
